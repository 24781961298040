import Vue from 'vue'
import App from './App.vue'
import CoreUIVue from '@coreui/vue'
import router from './router/index'
import { icons } from './assets/icons/icons'
import { store } from './store/index'
import './registerServiceWorker'
import CoreuiVueCharts from '@coreui/vue-chartjs'

Vue.config.productionTip = false
Vue.use(CoreUIVue)
Vue.use(CoreuiVueCharts)

new Vue({
  router,
  store,
  icons,
  render: h => h(App),
}).$mount('#app')
