import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        isExamInProgress: false,
        timer:false,
        warning:false,

    },
    getters: {
        timer: state => { 
            return state.timer;
        },
        isExamInProgress: state => { 
            return state.isExamInProgress;
        }
    },
    mutations: {
        setTimer(state, value) {
            state.timer = value;
        },
        setExamStatus(state, value) { 
            state.isExamInProgress = value;
        }
    }
})