<template>
  <div :class="{ root: hidden }">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      hidden: false,
    };
  },
  //disable Print Screen
  /*created(){
    window.addEventListener('keydown',(event)=>{
      console.log(event.code);
    })
      window.addEventListener('keyup',(event)=>{
        console.log(event.key)
      })
        window.addEventListener('focus',()=>{
          this.hidden = false;
        })
    }*/
};
</script>
<style lang="scss">
@import "~@coreui/coreui/scss/coreui";
@import url("https://fonts.googleapis.com/css2?family=Gudeo");
body {
  font-family: "Gudea";
}
</style>
