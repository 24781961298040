import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// auth
const Login = () => import('@/auth/Login')
const AutoLogin = () => import('@/auth/AutoLogin')
const SignUp = () => import('@/auth/SignUp')

//Conatiner
const Container = () => import('@/components/Container')

//Views
const ExamDetails = () => import('@/views/exam/ExamDetails')
const ExamPaper = () => import('@/views/exam/paper/ExamPaper')
const ExamResult = () => import('@/views/Result')
const ExamFeedback = () => import('@/views/exam/ExamFeedback')

const ExamEnd = () => import('@/views/exam/ExamEnd')

const router = new Router({
    mode: 'history',
    routes: configRoutes()
});

function configRoutes() {
    return [
        {
            path: '/',
            name: 'Container',
            component: Container,
            meta: {
                requireAuth: true
            },
            children: [
                {
                    path: 'exam/details',
                    name: 'ExamDetails',
                    component: ExamDetails,
                },
                {
                    path: 'exam/paper',
                    name: 'ExamPaper',
                    component: ExamPaper,
                },
                {
                    path: 'exam/feedback',
                    name: 'ExamFeedback',
                    component: ExamFeedback,
                },
                {
                    path: 'exam/result',
                    name: 'ExamResult',
                    component: ExamResult,
                },
                {
                    path: 'exam/end',
                    name: 'ExamEnd',
                    component: ExamEnd
                },
            ]
        },
        {
            path: '/auth/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/autologin/:autoLoginKey',
            name: 'AutoLogin',
            component: AutoLogin
        },
        {
            path: '/auth/register',
            name: 'Sign Up',
            component: SignUp
        },
    ]
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (to.name === 'Container') { 
            localStorage.clear()
            sessionStorage.clear()
            next();
        }
        const auth = JSON.parse(localStorage.getItem('examData'));
        if (!auth) next({path: '/auth/login'});
        else next();
    } else next();
})


export default router